<template>
  <div class="bookmaster-wraper">
    <app-modal-confirm
      ref="apikey_modal_delete"
      :modal-header-title="$t('common_vn.delete confirm title')"
      @confirmed="deleteApiKey()"
    >
      <template v-slot:body>
        <p class="confirm-body">{{ $t("common_vn.delete confirm") }}</p>
      </template>
    </app-modal-confirm>
    <validation-observer
      ref="form_create_entry"
      v-slot="{ passes, valid, validated }"
    >

      <form class="form-horizontal">
        <div class="row">
          <div class="col-12">
            <div class="card card-body">
              <!-- Restaurant name -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("student_invoice.year_month") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-date-range-picker
                    name="year_month"
                    rules="required"
                    timePicker24Hour="false"
                    format="YYYY-MM"
                    yearAndMonthOnly="true"
                    v-model="entry.year_month"
                  />
                </div>
              </div>

              <!-- Address VN -->
              <div class="form-group row">
                <label class="col-xl-1 col-lg-2 col-sm-2 col-form-label">{{ $t("student_invoice.collection_date") }} <span class="app-require">＊</span></label>
                <div class="col-xl-5 col-lg-8 col-sm-8">
                  <app-date-range-picker
                    name="collection_date"
                    rules="required"
                    timePicker24Hour="false"
                    format="YYYY-MM-DD"
                    v-model="entry.collection_date"
                  />
                </div>
              </div>

              <div class="card-footer row justify-content-end">
                <button type="button" :disabled="!valid && validated" class="btn btn-default m-r-10" @click="redirectDefaultValue">
                  {{ $t('common_vn.cancel') }}
                </button>
                <button :disabled="!valid && validated" class="btn btn-primary m-r-10" type="button" @click="createStudentInvoices" >
                  {{  $t('common_vn.create') }}
                </button>
              </div>
            </div>
            <!-- /.card -->
          </div>
        </div>
      </form>
    </validation-observer>
  </div>
</template>

<script>
import AppTextarea from "../../../components/forms/AppTextarea";
import AppDateRangePicker from "../../../components/forms/AppDateRangepicker";
import {ROUTES} from "@constants";
export default {
  name: "RestaurantCreateOrEdit",

  components: {
    AppTextarea,
    AppDateRangePicker,
  },

  data() {
    return {
      entry: {
        year_month: moment().subtract(1, 'months').format('YYYY-MM'),
        collection_date: ''
      },
      startValidate : false
    };
  },
  watch: {
  },

  methods: {
    async createStudentInvoices() {
      this.startValidate = true;
      if (!await this.$refs.form_create_entry.validate()) {
        return;
      }
      let res = null;
      let msg = "";
      let params = {
        year_month: moment(this.entry.year_month, 'YYYY-MM').startOf('month').format('YYYY-MM-DD'),
        collection_date: this.entry.collection_date
      }
      res = await this.$request.post(this.ENDPOINT.KYY_STUDENT_INVOICE_CREATE, params);
      msg = this.$t("common.msg create ok")
      if (res.hasErrors()) {
        this.__noticeError(res.data.error_msg != undefined ? res.data.error_msg : this.$t('common.error'));
      } else {
        this.__noticeSuccess(msg);
        await this.redirectDefaultValue();
      }
    },

    async redirectDefaultValue() {
      await this.$router.push({
        name: this.ROUTES.ADMIN.KYY_STUDENT_INVOICE_LIST, query: {
          'filters.year_month.equal': moment().subtract(1, 'months').format('YYYY-MM')
        }
      });
    },
  },
};
</script>
<style>
@media (min-width: 1200px) {
  .col-unit {
    flex: 0 0 14%;
  }
}

</style>
